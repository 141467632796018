import { Container } from "@mui/material";

const FourOFourPage = () => {
  return (
    <Container>
      <h2>404</h2>
      <div>
        Oh no! You seem to have tried to hit a page that does not exist.
      </div>
    </Container>
  );
};

export default FourOFourPage;
