import { Container } from "@mui/material";
import * as React from "react";
import ImageGallery from "react-image-gallery";

const publicUrl =
  process.env.NODE_ENV === "development"
    ? ""
    : "https://d1e8npr9kdrnr5.cloudfront.net/main/latest";

const images = [
  {
    thumbnail: `${publicUrl}/images/1.jpg`,
    original: `${publicUrl}/images/1-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/2.jpg`,
    original: `${publicUrl}/images/2-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/3.jpg`,
    original: `${publicUrl}/images/3-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/4.jpg`,
    original: `${publicUrl}/images/4-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/5.jpg`,
    original: `${publicUrl}/images/5-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/6.jpg`,
    original: `${publicUrl}/images/6-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/7.jpg`,
    original: `${publicUrl}/images/7-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/8.jpg`,
    original: `${publicUrl}/images/8-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/9.jpg`,
    original: `${publicUrl}/images/9-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/10.jpg`,
    original: `${publicUrl}/images/10-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/11.jpg`,
    original: `${publicUrl}/images/11-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/12.jpg`,
    original: `${publicUrl}/images/12-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/13.jpg`,
    original: `${publicUrl}/images/13-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/14.jpg`,
    original: `${publicUrl}/images/14-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/15.jpg`,
    original: `${publicUrl}/images/15-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/16.jpg`,
    original: `${publicUrl}/images/16-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/17.jpg`,
    original: `${publicUrl}/images/17-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/18.jpg`,
    original: `${publicUrl}/images/18-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/19.jpg`,
    original: `${publicUrl}/images/19-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/20.jpg`,
    original: `${publicUrl}/images/20-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/21.jpg`,
    original: `${publicUrl}/images/21-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/22.jpg`,
    original: `${publicUrl}/images/22-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/23.jpg`,
    original: `${publicUrl}/images/23-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/24.jpg`,
    original: `${publicUrl}/images/24-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/25.jpg`,
    original: `${publicUrl}/images/25-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/26.jpg`,
    original: `${publicUrl}/images/26-orig.jpg`,
  },
];

export default function ArtImageGallery() {
  return (
    <Container component="section" sx={{ mt: 2 }}>
      <ImageGallery lazyLoad={true} items={images}></ImageGallery>
    </Container>
  );
}
