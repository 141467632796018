import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import "./ProductValues.css";
const awakeImage = require("../../images/awake.jpg");
const asleepImage = require("../../images/asleep.jpg");
const fantasyImage = require("../../images/fantasy.jpg");
const preemieImage = require("../../images/preemie.jpg");
const bodyImage = require("../../images/body.png");
const partsImage = require("../../images/parts.jpg");

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={awakeImage}
                alt="suitcase"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Awake
              </Typography>
              <Typography variant="h5">
                Does your new baby want to see the world? Open-eyed babies have
                polymer-acrylic shatter-proof eyes in any baby shade, from
                newborn blue to oriental brown.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={asleepImage}
                alt="graph"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Asleep
              </Typography>
              <Typography variant="h5">
                Maybe your baby is a sleepy-head. No worries, they’re still just
                as sweet with baby lashes to complement their hair color.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={fantasyImage}
                alt="clock"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Fantasy
              </Typography>
              <Typography variant="h5">
                It all begins with an idea. If you can imagine, I can create it.
                Fantasy babies begin just like the others with a vinyl kit and
                cloth body. Purple eyes, yellow eyes, cat eyes, and every color
                in between; it’s all possible with a fantasy baby.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={preemieImage}
                alt="clock"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Micro-Preemies and Preemies
              </Typography>
              <Typography variant="h5">
                Babies can range from a 25 week gestation preemie to
                just-a-little-early preemie. There are many reasons to want a
                preemie doll: to aid in healing after a loss, for a big brother
                or sister to a preemie, or for your boutique to model preemie
                clothes. Or maybe your little one just wants a smaller size
                doll. Whatever your needs, I can create just the baby for you.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={partsImage}
                alt="clock"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                The Parts
              </Typography>
              <Typography variant="h5">
                All dolls begin as kits with vinyl arms, legs, and head. Kits
                begin either as sculpts by artists or 3D scans of real babies
                (called Realborns) Arms can be 3/4 length, or full (ends at the
                shoulder.) Legs can be 3/4 length or full (ends at the diaper)
                Some kits have a full vinyl body.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={bodyImage}
                alt="clock"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                The Body
              </Typography>
              <Typography variant="h5">
                Bodies are doe-suede in nude or ethnic brown, designed to fit
                the sculpt For a super cuddly baby, opt for a full cloth body
                with only the vinyl head.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
